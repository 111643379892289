.header {
    display: flex;
    justify-content: center;
    padding: 15px;
    border-bottom: 1px solid black;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    text-align: center;

    line-height: 30px;
    font-size: 1.1em;
}

.contactSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactSection__text {
    margin-bottom: 50px;
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.contactFormInput {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 25px;
}

.contactFormInput__input {
    height: 40px;
    font-size: 1em;
}

.contactFormInput__area {
    height: 125px;
}

.contactForm__loading {
    align-self: center;
    width: 100px;
}

.contactForm-submit {
    align-self: center;
    width: 150px;
    height: 35px;
    border-radius: 25px;
    font-size: 1rem;
}

.footer {
    display: flex;
    justify-content: center;
    padding: 10px;
    border-top: 1px solid black;
}

.workImage {
    width: 180px;
    margin-top: 60px;
    margin-bottom: 60px;
    border: 2px solid black;
}

.errorText {
    color: red;
    font-size: 0.8em;
    margin-top: 0.5em;
}